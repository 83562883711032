@font-face {
    font-family: 'CormorantGaramond';
    font-style: "normal";
    font-weight: 100;
    src: url("../Cormorant_Garamond/CormorantGaramond-Light.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "normal";
    font-weight: 300;
    src: url("../Cormorant_Garamond/CormorantGaramond-Regular.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "normal";
    font-weight: 500;
    src: url("../Cormorant_Garamond/CormorantGaramond-Medium.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "normal";
    font-weight: 700;
    src: url("../Cormorant_Garamond/CormorantGaramond-SemiBold.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "normal";
    font-weight: 900;
    src: url("../Cormorant_Garamond/CormorantGaramond-Bold.ttf") format('ttf');
}

@font-face {
    font-family: 'CormorantGaramond';
    font-style: "italic";
    font-weight: 100;
    src: url("../Cormorant_Garamond/CormorantGaramond-LightItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "italic";
    font-weight: 300;
    src: url("../Cormorant_Garamond/CormorantGaramond-Italic.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "italic";
    font-weight: 500;
    src: url("../Cormorant_Garamond/CormorantGaramond-MediumItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "italic";
    font-weight: 700;
    src: url("../Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'CormorantGaramond';
    font-style: "italic";
    font-weight: 900;
    src: url("../Cormorant_Garamond/CormorantGaramond-BoldItalic.ttf") format('ttf');
}