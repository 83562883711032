.TitleCard {
    height: 50%;
    margin-top: 3%;
}

.title {
    font-family: "CormorantGaramond";
    font-weight: 500;
    font-style: italic;
    font-size: 5rem;
    margin: 0 !important;
    margin-top: 10%;
    text-align: center;
    color: #421500;
}

.logo_aligner {
    width: 100%;
    text-align: center;
    height: 35%;    
    margin-top: 2vh;
    margin-bottom: 10vh;
}
.logo {
    height: 100%;
}

.subtitle {
    font-family: "CormorantGaramond";
    font-weight: 500;
    font-size: 3rem;
    margin: 0 !important;
    text-align: center;
    color: #421500;
}