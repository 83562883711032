@import url("./fonts/css/Cormorant_Garamond.css");
@import url("./fonts/css/EB_Garamond.css");
@import url("./fonts/css/Poppins.css");

html {
    margin: 0;
    height: 100vh;
}
body {
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: url("./img/Cursor_small.svg"), pointer;
}
#root {
    margin: 0;
    width: 100%;
    height: 100%;
}