@font-face {
    font-family: 'EbGaramond';
    font-style: "normal";
    font-weight: 300;
    src: url("../EB_Garamond/static/EBGaramond-Regular.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "normal";
    font-weight: 500;
    src: url("../EB_Garamond/static/EBGaramond-Medium.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "normal";
    font-weight: 700;
    src: url("../EB_Garamond/static/EBGaramond-SemiBold.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "normal";
    font-weight: 900;
    src: url("../EB_Garamond/static/EBGaramond-Bold.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "normal";
    font-weight: 900;
    src: url("../EB_Garamond/static/EBGaramond-ExtraBold.ttf") format('ttf');
}

@font-face {
    font-family: 'EbGaramond';
    font-style: "italic";
    font-weight: 300;
    src: url("../EB_Garamond/static/EBGaramond-Italic.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "italic";
    font-weight: 500;
    src: url("../EB_Garamond/static/EBGaramond-MediumItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "italic";
    font-weight: 700;
    src: url("../EB_Garamond/static/EBGaramond-SemiBoldItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "italic";
    font-weight: 900;
    src: url("../EB_Garamond/static/EBGaramond-BoldItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'EbGaramond';
    font-style: "italic";
    font-weight: 1000;
    src: url("../EB_Garamond/static/EBGaramond-ExtraBoldItalic.ttf") format('ttf');
}