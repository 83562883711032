@font-face {
    font-family: 'Poppins';
    font-style: "normal";
    font-weight: 100;
    src: url("../Poppins/Poppins-Light.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "normal";
    font-weight: 300;
    src: url("../Poppins/Poppins-Regular.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "normal";
    font-weight: 500;
    src: url("../Poppins/Poppins-Medium.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "normal";
    font-weight: 700;
    src: url("../Poppins/Poppins-SemiBold.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "normal";
    font-weight: 900;
    src: url("../Poppins/Poppins-Bold.ttf") format('ttf');
}

@font-face {
    font-family: 'Poppins';
    font-style: "italic";
    font-weight: 100;
    src: url("../Poppins/Poppins-LightItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "italic";
    font-weight: 300;
    src: url("../Poppins/Poppins-Italic.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "italic";
    font-weight: 500;
    src: url("../Poppins/Poppins-MediumItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "italic";
    font-weight: 700;
    src: url("../Poppins/Poppins-SemiBoldItalic.ttf") format('ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: "italic";
    font-weight: 900;
    src: url("../Poppins/Poppins-BoldItalic.ttf") format('ttf');
}